export default class LessonPage {
    constructor() {
        this.nameLessons = $('.js-lessons-open');

        this.init();
    }


    openLessonList() {
        this.nameLessons.on('click', function () {
            const icon = $(this).find('i');
            if (icon.hasClass('bi-arrow-up')) {
                icon.removeClass('bi-arrow-up');
                icon.addClass('bi-arrow-down');
            } else {
                icon.addClass('bi-arrow-up');
                icon.removeClass('bi-arrow-down');
            }
        })
    }


    init() {
        this.openLessonList();
    }
}