import * as toastr from 'toastr';


export default class ProfileSettings {
    constructor() {
        this.editBtn = $('.js-edit-personal-info-btn');
        this.cancelBtn = $('.js-edit-personal-info-btn[data-showform="false"]');
        this.formWrap = $('.js-form-wrap');
        this.btnsSaveCancel = $('.js-save-cancel-form');
        this.btnSave = $('.js-save-form');

        this.init();
    }

    toggleForm() {
        const _this = this;
        this.editBtn.on('click', function () {
            const showForm = $(this).attr('data-showForm') === 'true';
            _this.toggleFieldForm(showForm);
            if (!showForm) {
                _this.editBtn.show();
            } else {
                $(this).hide();
            }
            _this.btnsSaveCancel[!showForm ? 'hide' : 'show']();
        })
    }

    toggleFieldForm(showForm) {
        let [fromType, toType] = showForm ? ['hidden', 'text'] : ['text', 'hidden'];
        this.formWrap.find('.js-readonly-field')[showForm ? 'hide' : 'show']();
        this.formWrap.find(`input[type="${fromType}"]`).each((_, item) => $(item).prop('type', toType));
    }

    updateNames() {
        for (const [nameInput, valInput] of Object.entries(this.fields)) {
            const hiddenInput = $(`[name="${nameInput}"]`);
            hiddenInput.closest('.js-field-wrap').find('.js-readonly-field').text(valInput);
            hiddenInput.val(valInput);
        }
    }

    changeNamesSubmitForm() {
        const _this = this;
        this.btnSave.on('click', function () {
            const firstName = $('[name="first_name"]').val();
            const lastName = $('[name="last_name"]').val();
            const phoneNumber = $('[name="phone_number"]').val();
            _this.fields = {
                'first_name': firstName,
                'last_name': lastName,
                'phone_number': phoneNumber,
            }
            const csrftoken = $('[name="csrfmiddlewaretoken"]').val();
            const url = $(this).attr('data-ajaxurl');
            const userid = $(this).attr('data-userid');
            fetch(url, {
                'method': 'POST',
                'body': JSON.stringify({
                    'first_name': firstName,
                    'last_name': lastName,
                    'user_id': userid,
                    'phone_number': phoneNumber,
                }),
                'headers': {
                    'X-CSRFToken': csrftoken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then(data => {
                    if (data.status) {
                        toastr.success("Оновлення даних", "Успішно!", {positionClass: "toast-bottom-right"});
                        _this.cancelBtn.trigger('click');
                        _this.updateNames();
                    } else if (!data.status && data.error) {
                        toastr.error("Оновлення даних", data.error, {positionClass: "toast-bottom-right"});
                    }
                })
        })
    }


    init() {
        this.toggleForm();
        this.changeNamesSubmitForm();
    }

}

