import * as toastr from 'toastr';

export default class ProfileSecurity {

    constructor() {
        this.btnChangePassword = $('.js-btn-change-password');
        this.csrfToken = $('[name="csrfmiddlewaretoken"]').val();
        this.oldPasswordField = $('[name="old_password"]');
        this.newPassword1Field = $('[name="new_password1"]');
        this.newPassword2Field = $('[name="new_password2"]');
        this.resetValidateRules();

        this.init();
    }

    toggleHelpText() {
        for (const [key, isValid] of Object.entries(this.validateRules)) {
            const helpText = $(`#${key}`);
            helpText.removeClass('validation-true validation-false');
            helpText.addClass(`validation-${isValid}`);
        }
        let totalValidations = Object.values(this.validateRules);
        const isEnabledBtn = totalValidations.length === totalValidations.filter(v => v === true).length;
        this.btnChangePassword.prop('disabled', !isEnabledBtn);
    }

    sendNewPassword() {
        const _this = this;
        this.btnChangePassword.on('click', function (e) {
            e.preventDefault();
            const url = $(this).attr('data-ajaxurl');
            const userId = $(this).attr('data-userId');
            const oldPassword = _this.oldPasswordField.val();
            const newPassword1 = _this.newPassword1Field.val();
            const newPassword2 = _this.newPassword2Field.val();
            if (!oldPassword.length) {
                toastr.warning('Введіть поточний пароль', '', {positionClass: "toast-bottom-right"})
                return;
            }
            let totalValidations = Object.values(_this.validateRules);
            const isEnabledBtn = totalValidations.length === totalValidations.filter(v => v === true).length;
            if (!isEnabledBtn) {
                confirm('Нам здалося, чи Ви намагались нас обдурити?');
                return
            }
            fetch(url, {
                'method': 'POST',
                'body': JSON.stringify({
                    'old_password': oldPassword,
                    'new_password1': newPassword1,
                    'new_password2': newPassword2,
                    'user_id': userId,
                }),
                'headers': {
                    'X-CSRFToken': _this.csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then(data => {
                    if (!data.status) {
                        toastr.error(data.error, "Помилка", {positionClass: "toast-bottom-right"})
                    } else {
                        toastr.success(data.message, 'Все пройшло добре', {positionClass: "toast-bottom-right"})
                        _this.clearFields();

                    }
                })
        })
    }

    clearFields() {
        this.oldPasswordField.val('');
        this.newPassword1Field.val('');
        this.newPassword2Field.val('');
        this.resetValidateRules();
        this.toggleHelpText();
    }

    resetValidateRules() {
        this.validateRules = {
            'length': false,
            'upperCase': false,
            'digit': false,
            'same': false,
        };
    }

    checkPassword(field) {
        const value = field.value;
        this.resetValidateRules();
        if (!value.length) {
            return;
        }
        this.validateRules.length = value.length >= 8;
        for (let char of value) {
            if ('A' <= char && char <= 'Z') {
                this.validateRules.upperCase = true;
            }
            if ('0' <= char && char <= '9') {
                this.validateRules.digit = true;
            }
        }

        this.validateRules.same = this.newPassword2Field.val() === this.newPassword1Field.val();
        this.toggleHelpText();
    }

    addListenersChangeFields() {
        const _this = this;
        for (const passwordField of [this.newPassword1Field, this.newPassword2Field]) {
            passwordField.on('input', function () {
                _this.checkPassword(this);
            })
        }
    }

    init() {
        this.addListenersChangeFields();
        this.sendNewPassword();
    }
}

