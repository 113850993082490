export default class Quiz {
    constructor() {
        this.switchBtnQuestion = $('.js-switch-question');
        this.questionBlocks = $('.js-question-block');
        this.choiceRadio = $('.js-choice-radio');
        this.sendResultBtn = $('.js-send-result');
        this.countQuestion = parseInt($('.js-count-question').val());
        this.csrfToken = $('[name="csrfmiddlewaretoken"]').val();
        this.blockQuestionId = parseInt($('[name="block_question_id"]').val());
        this.init();
    }


    switchQuestion() {
        const _this = this;
        this.switchBtnQuestion.on('click', function () {
            const num = $(this).attr('data-num');
            _this.questionBlocks.addClass('hide');
            $(`.js-question-block[data-index="${num}"]`).removeClass('hide');
        })
    }

    listenerAnswer() {
        const _this = this;
        this.choiceRadio.on('change', function () {
            const numberQuestion = $(this).closest('.js-question-block').attr('data-index');
            $(`.js-switch-question[data-num="${numberQuestion}"]`).addClass('primary');
            _this.checkIfFinish();
        })
    }

    checkIfFinish() {
        const finishedQuestionCount = $('.js-choice-radio:checked').length;
        if (finishedQuestionCount === this.countQuestion) {
            this.sendResultBtn.attr('disabled', false);

        }
    }

    showResult(resultMap){
        let resultTemplate = '';
        let countCorrectAnswer = 0;
        this.questionBlocks.each(function(index, questionBlock){
            const questionId = $(questionBlock).attr('data-question_id');
            const questionText = $(questionBlock).attr('data-text');
            resultTemplate += `<div style="margin-bottom: 15px;"> <h5> ${questionText} </h5>`;

            if (!resultMap.hasOwnProperty(questionId)){
                const selectAnswer = $(questionBlock).find('.js-choice-radio:checked').closest('label').find('.js-choice-text').text()
                resultTemplate += `<p class="answer correct">${ selectAnswer } </p>`
                countCorrectAnswer++;
            } else {
                const {correct_answer, your_answer }  = resultMap[questionId];
                resultTemplate += `<p class="answer incorrect">  <b>Ваша відповідь:</b> ${your_answer} </p>`;
                resultTemplate += `<p class="answer correct">  <b>Правильна відповідь:</b> ${correct_answer} </p>`;
            }
            resultTemplate += '</div>';
        })
        const templateCountAnswers = `<b> Ви відповіли  правильно на ${countCorrectAnswer} з ${this.countQuestion}</b>`;
        $('.js-quiz-title').append(templateCountAnswers);
        $('#output_quiz').html(resultTemplate);
    }
    initSubmitQuiz() {
        const _this = this;
        this.sendResultBtn.on('click', function () {
            const url = $(this).attr('data-url');
            let payload = {};

            $('.js-question-block').each(function(index, question){
                const questionId = $(question).attr('data-question_id');
                const answerId = $(question).find('input[type="radio"]:checked').val();
                payload[questionId] = answerId;
            })
            fetch(url, {
                'method': 'POST',
                'body': JSON.stringify({
                    'block_question_id': _this.blockQuestionId,
                    'data': payload
                }),
                'headers': {
                    'X-CSRFToken': _this.csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then(data => {
                    const result = data.result;
                    _this.showResult(result);
                    console.log('data', data);
                })
            console.log(url);
        })
    }


    init() {
        this.switchQuestion();
        this.listenerAnswer();
        this.initSubmitQuiz();
    }
}