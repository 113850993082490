export default class ReviewFormPopup {
    constructor() {
        this.submitBtn = $('.js-submit-review-btn');
        this.csrftoken = $('[name="csrfmiddlewaretoken"]').val();
        this.reviewFormPopupWrap = $('.js-review-form-popup-wrap');
        this.successText = this.reviewFormPopupWrap.find('.js-thanks-text');
        this.errorText = this.reviewFormPopupWrap.find('.js-error-text');
        this.closePopUpBtn = $('.js-review-popup-close');

        this.init();
    }

    showMessage(success) {
        this.reviewFormPopupWrap.find('form').hide();
        this.reviewFormPopupWrap.find('form')[0].reset();
        this.hideAllMessages();
        if (success) {
            this.successText.show();
        } else {
            this.errorText.show();
        }
        setTimeout(() => {
            this.closePopUp();
        }, 3000)
    }

    closePopUp() {
        this.closePopUpBtn.trigger('click');
        $('.pulse').removeClass('pulse');
        setTimeout(() => {
            this.hideAllMessages();
            this.reviewFormPopupWrap.find('form').show();
        }, 1000)
    }

    hideAllMessages() {
        this.successText.hide();
        this.errorText.hide();
    }

    submitReview() {
        const _this = this;
        this.submitBtn.on('click', function (event) {
            event.preventDefault();
            const courseId = $('[name="course_id"]').val();
            const msg = $('[name="msg"]').val();
            const rate = $('[name="rate"]:checked').val();
            const userId = $('[name="user_id"]').val();
            const url = $(this).attr('data-ajaxurl');

            fetch(url, {
                'method': 'POST',
                'body': JSON.stringify({
                    'course_id': courseId,
                    'msg': msg,
                    'user_id': userId,
                    'rate': rate,
                }),
                'headers': {
                    'X-CSRFToken': _this.csrftoken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then(data => {
                    _this.showMessage(data.status);
                })
        })
    }

    init() {
        this.submitReview();
    }
}

