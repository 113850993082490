export default class HomeTask {
    constructor() {
        this.selectorSelectItemsByLessons = '.js-filter-rows-by-lesson';
        this.selectorSelectItemsByRows = '.js-filter-rows-by-lesson';
        this.selectorSelectItemsByRows = '.js-filter-rows-by-state';
        this.dataAttrs = {
            courseId: 'data-course_id',
            lessonId: 'data-lesson_id',
            state: 'data-state',
        };

        this.init();
    }

    tiggerFilter(select) {
        const courseId = $(select).attr(this.dataAttrs.courseId);
        const selectedLesson = $(`${this.selectorSelectItemsByLessons}[${this.dataAttrs.courseId}="${courseId}"]`).val();
        const selectedState = $(`${this.selectorSelectItemsByRows}[${this.dataAttrs.courseId}="${courseId}"]`).val();
        let selectorActiveRows = '';
        if (selectedLesson.length !== 0) {
            selectorActiveRows += `[${this.dataAttrs.lessonId}="${selectedLesson}"]`;
        }
        if (selectedState.length !== 0) {
            selectorActiveRows += `[${this.dataAttrs.state}="${selectedState}"]`;
        }
        const targetRowsSelector = `tr[${this.dataAttrs.courseId}="${courseId}"]`

        if (selectorActiveRows.length) {
            $(targetRowsSelector).hide();
            $(selectorActiveRows).show();
        } else {
            $(targetRowsSelector).show();
        }
        console.log('selectorActiveRows', selectorActiveRows);
    }

    handlerChange() {
        const component = this;
        $(this.selectorSelectItemsByLessons).on('change', function (e) {
            component.tiggerFilter(this);
        });
        $(this.selectorSelectItemsByRows).on('change', function (e) {
            component.tiggerFilter(this);
        });
    }

    init() {
        this.handlerChange();
    }
}
