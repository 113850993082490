import * as toastr from 'toastr';

export default class HomeTaskForm {
    constructor() {
        this.homeTaskForms = document.querySelectorAll('.js-home-task');
        const typeCourse = document.querySelector('[name="type_course"]');
        this.hasHomeTask = Boolean(typeCourse?.value);
        if (this.hasHomeTask) {
            this.typeCourse = typeCourse.value.split('_')[0];
            this.validFileExtention = ['zip'];
            switch (this.typeCourse) {
                case 'py': {
                    this.validFileExtention.push('py');
                    break;
                }
                case 'js':
                case 'html':
                case 'css': {
                    this.validFileExtention.push('html', 'css', 'js')
                    break;
                }
            }
        }

        this.init();
    }

    validateHomeTaskForm() {
        const _this = this;
        for (let i = 0; i < this.homeTaskForms.length; i++) {
            (function (index) {
                const currentForm = _this.homeTaskForms[index];
                currentForm.addEventListener('submit', function (e) {
                    const fileName = this.file.value;
                    const fileExt = fileName.split('.').pop();
                    if (!this.link_to_git.value && fileName.length === 0) {
                        toastr.error('Завантажте файл або додайте посилання', ``, {positionClass: "toast-bottom-right"});
                        e.preventDefault();
                        return false;
                    }
                    if (fileName.length !== 0 && _this.validFileExtention.indexOf(fileExt) === -1) {
                        toastr.error('Помилка завантаження файлу.', `Має бути файл типу ${_this.validFileExtention.join(',')}`, {positionClass: "toast-bottom-right"});
                        e.preventDefault();
                        return false;
                    }

                    const attachLink = this.link_to_git.value;
                    if (attachLink) {
                        try {
                            new URL(attachLink);
                        } catch (_) {
                            toastr.error('Посилання не валідне', ``, {positionClass: "toast-bottom-right"});
                            e.preventDefault();
                            return false;
                        }
                    }
                })
            })(i);
        }

    }

    addHelpTextFileExt() {
        if (!this.hasHomeTask) return;

        const validFileExts = this.validFileExtention.join(' , ');
        for (let i = 0; i < this.homeTaskForms.length; i++) {
            const helpText = document.createElement('small');
            helpText.innerHTML = `Валідні формати файлів: <b> ${validFileExts} </b>`;
            $(this.homeTaskForms[i]).find('.drop-zone').after(helpText);
        }
    }

    init() {
        this.validateHomeTaskForm();
        this.addHelpTextFileExt();
    }
}

