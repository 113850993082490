import Form from './components/form';
import HomeTaskForm from './components/homeTaskForm';
import Sidebar from './components/sidebar';
import Quiz from './components/quiz';

import ReviewFormPopup from './popups/reviewForm';

import HomeTask from './pages/homeTasks';
import ProfileSettings from './pages/profileSettings';
import ProfileSecurity from './pages/profileSecurity';
import LessonPage from './pages/lessonPage';

import $ from 'jquery';



class StudentApp {
    constructor() {
        this.form = Form;
        this.homeTask = HomeTask;
        this.homeTaskForm = HomeTaskForm;
        this.reviewFormPopup = ReviewFormPopup;
        this.sidebar = Sidebar;
        this.profileSettings = ProfileSettings;
        this.profileSecurity = ProfileSecurity;
        this.Quiz = Quiz;
        this.LessonPage = LessonPage;
    }


}

var studentApp = new StudentApp();

window.studentApp = studentApp;

window.$ = $;
