export default class Sidebar {
    constructor() {
        this.sidebarSelector = '.sidebar';
        this.toggleSidebarSelector = '.toggle-sidebar';
        this.sidebarPageSelector = '.page-with-sidebar';
        this.closeClass = 'close';

        this.init();
    }

    toggleSidebar() {
        if ($(this.sidebarSelector).length <= 0) return false;
        let _this = this;
        if (screen.width <= 1024) {
            $(this.sidebarPageSelector).addClass(this.closeClass);
            $(this.sidebarSelector).addClass(this.closeClass);
            $(this.sidebarSelector).removeClass('load');
        }
        $(this.toggleSidebarSelector).click(function (e) {
            let page = e.currentTarget.closest(_this.sidebarPageSelector);
            let sidebar = e.currentTarget.closest(_this.sidebarSelector);
            $(page).toggleClass(_this.closeClass);
            $(sidebar).toggleClass(_this.closeClass);
        })
    }

    init() {
        this.toggleSidebar();
    }
}
